<template>
  <div>
    <div class="content">
      <div class="iconfont icon-zanwu meiyoushuju"></div>
      <div class="txt">暂无数据</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "",
  data() {
    return {};
  },
  created() {},
  methods: {},
  mounted() {},
  computed: {},
};
</script>
<style lang="less" scoped>
.content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-bottom: 70px;
  .meiyoushuju{
    font-size: 120px;
    color: #9c9c9c;
    margin-top: 50px;
  }
  .txt{
    font-size: 16px;
    color: #9c9c9c;
    position: relative;
    top: -20px;
  }
}
</style>
