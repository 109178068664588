<template>
  <div>
    <div class="leftcontHead">
      <div class="boxs">
        <div
          class="box"
          :class="index == typeOn ? 'on' : ''"
          v-for="(item, index) in typeList"
          @click="typeClick(index)"
        >
          <div class="name">{{ item }}</div>
        </div>
      </div>
      <div class="inpBox">
        <input
          type="text"
          placeholder="请搜索您发布的内容"
          v-model="seachName"
          @keyup.enter="searchClick"
        />
        <div class="ssBox iconfont icon-search" @click="searchClick"></div>
      </div>
    </div>
    <div class="contList">
      <Kong v-if="list.length == 0" />
      <div class="cont" v-for="(item, index) in list">
        <div class="contHead">
          <div class="fbBox">
            <div class="phoimg">
              <img :src="$imgUrl + item.forum.memberavatar" alt="" />
            </div>
            <div class="name">{{ item.forum.membername }}</div>
            <div class="txt">{{ page.parent_id == 0 ? "论坛" : "评论" }}</div>
          </div>
          <div class="lxBox">
            <!-- <div class="box">
                      <div class="yuan">#</div>
                      <div class="name">类型类型</div>
                    </div> -->
            <div class="box hongb">
              <div class="yuanimg">
                <img src="../../assets/img/logo.png" alt="" srcset="" />
              </div>
              <div class="name">
                用户{{ page.parent_id == 0 ? "发布" : "评论" }}
              </div>
            </div>
          </div>
        </div>
        <div
          class="contTitle"
          v-if="item.forum.parent_id == 0"
          @click="ltjump(item.forum.id)"
        >
          {{ item.forum.name }}
        </div>
        <div class="contText c-3" @click="ltjump(item.forum.id)">
          {{ item.forum.content }}
        </div>
        <div class="funBox">
          <div class="boxs">
            <div class="box">
              <div class="iconfont icon-browse browse"></div>
              <div class="num">{{ item.forum.click }}</div>
            </div>
            <div class="box">
              <div class="iconfont icon-message message"></div>
              <div class="num">{{ item.forum.childrenlength }}</div>
            </div>
            <div
              class="box"
              style="cursor: pointer"
              @click="favoriteClick(item, index)"
            >
              <div
                class="iconfont praise"
                :class="
                  item.forum.is_favorite ? 'icon-praise_fill on' : 'icon-praise'
                "
              ></div>
              <div class="num">{{ item.forum.praise }}</div>
            </div>
            <div class="box">
              <div class="iconfont icon-send send"></div>
              <div class="num">分享</div>
              <div class="fxBox">
                <div class="lianjieBox dis-cent">
                  <div class="iconfont icon-lianjie lianjie"></div>
                </div>
                <div class="name" @click="lianjieClick(item)">复制链接</div>
              </div>
            </div>
          </div>
          <div class="time">{{ item.forum.create_time }}</div>
        </div>
      </div>
    </div>
    <div class="fenye" v-if="totalCount > 20">
      <a-pagination
        :default-current="page.pageNo"
        v-model="page.pageNo"
        :total="totalCount"
        @change="onChange"
      />
    </div>
  </div>
</template>

<script>
import Kong from "../component/kong.vue";
export default {
  components: {
    Kong,
  },
  name: "",
  data() {
    return {
      typeOn: 0,
      typeList: ["论坛", "评论"],
      judge: true,
      page: {
        pageNo: 1,
        parent_id: 0,
        pageSize: 20,
      },
      list: [],
      seachName: "",
      totalCount: 0,
    };
  },
  created() {
    this.listGet();
  },
  methods: {
    searchClick() {
      this.list = [];
      this.page.name = this.seachName;
      this.page.pageNo = 1;
      this.totalCount = 0
      this.listGet();
    },
    onChange(pageNumber) {
      this.list = [];
      this.page.pageNo = pageNumber;
      this.listGet();
    },
    listGet() {
      if (!this.judge) {
        return;
      }
      this.judge = false;
      this.$get("/web/favorite_gets", this.page).then((res) => {
        console.log(res.data);
        this.judge = true;
        this.totalCount = res.totalCount;
        if (res.data) {
          for (let i = 0; i < res.data.length; i++) {
            const e = res.data[i];
            e.forum.content = e.forum.content.replace(/<[^>]*>/g, "");
            this.list.push(e);
          }
          this.page.pageNo++;
        } else {
          if (this.page.pageNo != 1) {
            this.$message.warning("已展示全部内容");
          }
        }
      });
    },
    scrollEvent() {
      let top = document.querySelector("#ysCont").scrollTop;
      let height = document.querySelector("#ysCont").scrollHeight;
      let offHeight = document.querySelector("#ysCont").offsetHeight;
      if (offHeight + top - height >= 0) {
        this.listGet();
        // 需要执行的代码
      }
    },
    lianjieClick(item) {
      const textarea = document.createElement("textarea");
      textarea.value = item.name + " " + window.location.href;
      document.body.appendChild(textarea);
      textarea.select();
      document.execCommand("copy");
      document.body.removeChild(textarea);
      this.$message.success("复制成功");
    },
    typeClick(i) {
      this.typeOn = i;
      this.page.parent_id = i;
      this.page.pageNo = 1;
      this.list = [];
      this.totalCount = 0
      this.listGet();
    },
    ltjump(id) {
      if (this.page.parent_id == 1) {
        return;
      }
      this.$router.push({
        path: "/wzCont",
        query: {
          id: id,
        },
      });
    },
  },
  mounted() {},
  computed: {},
};
</script>
<style lang="less" scoped>
.leftcontHead {
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 37px;
  background-color: #fff;
  border-radius: 5px;
  .boxs {
    display: flex;
    align-items: center;
    line-height: 60px;
  }
  .box {
    margin-right: 44px;
    height: 58px;
    cursor: pointer;

    .name {
      font-size: 16px;
    }
  }

  .on {
    border-bottom: 3px solid #f66;

    // transition: all .3s linear;
    .name {
      font-weight: 700;
    }
  }
  .inpBox {
    width: 220px;
    border-radius: 35px;
    height: 35px;
    border: 1px solid #f66;
    position: relative;
    color: #f66;
    overflow: hidden;
    margin-right: 15px;
    input {
      width: 100%;
      height: 100%;
      box-sizing: border-box;
      padding-left: 15px;
      color: #f66;
    }
    .ssBox {
      top: 0;
      right: 0;
      height: 100%;
      width: 30px;
      line-height: 35px;
      font-size: 18px;
      position: absolute;
      cursor: pointer;
    }
  }
}
.contList {
  padding: 0 20px;
  background-color: #fff;
  margin-top: 10px;
  .cont {
    padding: 20px 0 15px;
    border-bottom: 1px solid #ededed;

    .contHead {
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 13px;

      .fbBox {
        display: flex;
        align-items: center;
        flex: 1;

        .phoimg {
          width: 40px;
          height: 40px;

          img {
            object-fit: cover;
            border-radius: 50%;
          }
        }

        .name {
          color: #505766;
          margin-left: 10px;
        }

        .txt {
          padding: 0 6px;
          font-size: 12px;
          line-height: 16px;
          font-weight: 600;
          margin-right: 8px;
          border: 1px solid #fb9900;
          color: #fb9900;
          border-radius: 2px;
          margin-left: 15px;
        }

        .txtlan {
          color: #7b8aff;
          border: 1px solid #7b8aff;
        }
      }

      .lxBox {
        display: flex;
        align-items: center;

        .box {
          padding: 0 12px;
          line-height: 28px;
          font-weight: 500;
          color: #333;
          text-align: center;
          font-size: 12px;
          border-radius: 14px;
          background: #eff9ff;
          margin-left: 16px;
          position: relative;
          cursor: pointer;
          display: flex;
          align-items: center;

          .yuan {
            border-radius: 50%;
            height: 20px;
            width: 20px;
            background: linear-gradient(to right, #00abee, #55c6f3);
            font-weight: 600;
            color: #fff;
            font-size: 14px;
            margin-right: 5px;
            display: flex;
            align-items: center;
            justify-content: center;
          }

          .yuanimg {
            border-radius: 50%;
            height: 20px;
            width: 20px;
            font-weight: 600;
            color: #fff;
            font-size: 14px;
            margin-right: 5px;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }

        .hongb {
          background-color: #ffefef;
        }
      }
    }

    .contTitle {
      font-size: 18px;
      font-weight: 600;
      color: #333;
      line-height: 22px;
      margin-bottom: 12px;
      cursor: pointer;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      span {
        margin-top: 1px;
        width: 36px;
        text-align: center;
        display: inline-block;
        height: 20px;
        font-size: 12px;
        line-height: 20px;
        font-weight: 600;
        margin-right: 8px;
        color: #fff;
        // background: #ff7a7a;
        background: #fb9900;
        position: relative;
        top: -1px;
        border-radius: 2px;
      }
    }

    .contText {
      color: #666;
      cursor: pointer;
    }

    .funBox {
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: #999;
      margin-top: 5px;

      .boxs {
        display: flex;

        .box {
          display: flex;
          align-items: center;
          margin-right: 15px;
          position: relative;
          .browse {
            font-size: 18px;
          }

          .message {
            font-size: 16px;
          }

          .praise {
            font-size: 16px;
          }

          .send {
            font-size: 16px;
          }

          .num {
            position: relative;
            top: -1px;
            font-size: 12px;
            margin-left: 2px;
          }
          .fxBox {
            position: absolute;
            top: 0;
            right: -100px;
            box-shadow: 0 0 4px 2px #ebebeb;
            background-color: #fff;
            display: none;
            width: 105px;
            border-radius: 5px;
            justify-content: center;
            align-items: center;
            height: 40px;
            cursor: pointer;
            .lianjieBox {
              color: #fff;
              background-color: #f56709;
              border-radius: 50%;
              height: 20px;
              width: 20px;
              margin-right: 5px;
              .lianjie {
                font-size: 12px;
              }
            }
          }
        }
        .box:hover {
          .fxBox {
            display: flex;
          }
        }
      }
    }
  }
}
</style>
